#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-layout-header {
  line-height: normal;
  display: flex;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.page-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
