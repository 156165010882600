.save-container {
  padding: 12px;
}

.divider {
  margin: 8px 0px;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.questionHeader {
  display: flex;
  justify-content: space-between;
}
