.form-container {
}

.add-team-form {
}

.team-form-button {
  background-color: #ff0a6c;
  border-color: #ff0a6c;
  color: white;
  margin-top: 20px;
}
.team-form-button:hover {
  background-color: white;
  border-color: #ff0a6c;
  color: #ff0a6c;
}
