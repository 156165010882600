.main {
  height: 100vh;
  background: linear-gradient(135deg, #ff0a6c, #2d27ff);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  background-color: white;
  padding: 36px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.login-container h2 {
  text-align: center;
  margin-bottom: 16px;
}

.site-form-item-icon {
}

.login-form-button {
  background-color: #ff0a6c;
  border-color: #ff0a6c;
  color: white;
}
.login-form-button:hover {
  background-color: white;
  border-color: #ff0a6c;
  color: #ff0a6c;
}

.ant-form-item-explain-error {
  color: #ff0a6c;
}
