.steps-content {
  padding-top: 10px !important;
}

.question-container {
  min-height: 40vh;
}
.question-container.ant-form-item {
  margin-bottom: 0px;
}
