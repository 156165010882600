.sider {
  background: #0b0705 !important;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ant-layout-sider-trigger {
  background: #0b0705 !important;
}
.ant-menu.ant-menu-dark {
  background: #0b0705;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ff0a6c;
}
