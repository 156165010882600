.edit-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
}
.title {
  text-align: center;
}
.title h1 {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.75);
}
